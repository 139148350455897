//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqHSx-abaJrzr6m0DDX9EhjL4-ek3mqfM",
  authDomain: "spotty-25747.firebaseapp.com",
  projectId: "spotty-25747",
  storageBucket: "spotty-25747.appspot.com",
  messagingSenderId: "1094875679752",
  appId: "1:1094875679752:web:e6c379aaac12b70a1cd5bd",
  measurementId: "G-WC3ZVMSXE1",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
